// Header Base

.app-header {
  height: $app-header-height;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 10;
  transition: all 0.2s;

  &.header-shadow {
    box-shadow: $box-shadow-default;
  }

  .app-header__content {
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1;
    padding: 0 $layout-spacer-x;
    height: $app-header-height;

    .app-header-left {
      display: flex;
      align-items: center;
    }

    .app-header-right {
      align-items: center;
      display: flex;
      margin-left: auto;
    }
  }

  .header-user-info {
    & > .widget-heading,
    & > .widget-subheading {
      white-space: nowrap;
    }

    & > .widget-subheading {
      font-size: $font-size-xs;
    }
  }
}
.header-icon {
  // display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 8px;
  border-radius: 50%;
  opacity: 0.8;
  width: 42px;
  height: 42px;
  background-color: rgba(255, 255, 255, 0.2);
}

.app-header__logo {
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  width: $app-sidebar-width;
  display: flex;
  align-items: center;
  transition: width 0.2s;

  // .logo-src {
  //   height: $logo-height;
  //   width: $logo-width;
  //   background: url('../../../assets/utils/images/logo-inverse.png');
  //   // background: url('../../../assets/utils/images/carto_fullcolor.svg');
  // }
}
.logo-src {
  width: 145px;
  height: 50px;
}
@media screen and (max-width: 400px) {
  .logo-src {
    width: 120px;
    height: 40px;
  }
}

.app-header__menu,
.app-header__mobile-menu {
  display: none;
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  align-items: center;
}

.header-calculator-icon {
  display: block;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: relative;
  color: rgba(255, 255, 255, 0.9);
  // width: 20px;
  // height: 20px;
  &::before {
    font-size: 20px;
  }
}

// Header Modifiers

@import 'modifiers/fixed-header';
@import 'modifiers/header-dots';
@import 'modifiers/header-megamenu';
@import 'modifiers/header-buttons';
//@import "modifiers/header-horizontal";

// Header Themes

@import 'themes/header-light';
@import 'themes/header-dark';
//@import "themes/header-inverted";
